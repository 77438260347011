import logo from './images/Logo.png';
import twiter_footer from './images/icons/twiter-footer.png';
import facebook_footer from './images/icons/facebook-footer.png';
import youtube_footer from './images/icons/youtube-footer.png'
import insta_footer from './images/icons/insta-footer.png';
import footer_location from './images/icons/footer-location.png';
import footer_call from './images/icons/footer-call.png';
import footer_whatsapp from './images/icons/footer-whatsapp.png';
import footer_email from './images/icons/footer-email.png';
import { Link, useNavigate } from 'react-router-dom';
import whatsappchat from './images/whatsapp-icon.png';
function Footer( props ) {
    const navigate = useNavigate();

    const scrollToContact = (e) => {
        
     e.preventDefault();
    
        navigate('/');
        setTimeout( ()=>{
            const element = document.getElementById("home-contactus");
            if(element){      
            element.scrollIntoView();
            }
        },10)

    }
    
    function scrollToTop(){
        window.scrollTo(0,0);
    }

    return (
        <>
        <footer>
            <div className="footer-section">

                <div className="pre-footer-logo-social">
                    <div className="container">
                        <div className="pre-footer">
                            <div className="footer-logo-section">
                                <img src={logo} alt='footerlogo' />
                            </div>
                            <div className="social-footer-section">
                                {/* <div className="single-footer-social">
                                    <a href="{props.data.twitter}"><img src={twiter_footer} alt='twiter-icon' /></a>
                                </div> */}
                                <div className="single-footer-social">
                                    <a href="https://www.facebook.com/profile.php?id=61554568832994" target='_blank'><img src={facebook_footer} alt='facebook-icon' /></a>
                                </div>
                                <div className="single-footer-social">
                                    <a href="https://www.instagram.com/travel_with_rittam?igsh=MXJsZ3Z1bzJveWtnZw==" target='_blank'><img src={youtube_footer} alt='youtube-icon' /></a>
                                </div>
                                <div className="single-footer-social">
                                    <a href="https://www.instagram.com/rh_adventures_holidays?igsh=NWs2eWZlYm9xZmJ2" target='_blank'><img src={insta_footer} alt='insta-icon' /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content-section">
                    <div className="container">
                        <div className="footer-data">
                            <div className="footer-left-section">
                                <div className="footer-title">
                                    Contact Us
                                </div>
                                <div className="section-data">
                                    <ul>
                                        <li><img src={footer_location} className="f-c-icon" /> {props.data.pgFooterAddress}</li>
                                        <li><img src={footer_call} className="f-c-icon" /> Call us: <a
                                            href="tel:{props.data.pgHeaderPhone}">{props.data.pgHeaderPhone} </a> </li>
                                        <li><img src={footer_whatsapp} className="f-c-icon" /> WhatsApp: {props.data.pgFooterWP}</li>
                                        <li><img src={footer_email} className="f-c-icon" /> <a
                                            href="mailto:{props.data.pgHeaderEmail}">{props.data.pgHeaderEmail}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-middle-section">
                                <div className="footer-title">
                                    Quick Links
                                </div>
                                <div className="section-data">
                                    <ul>
                                        <li>
                                        <Link to="/" className="footerlink" aria-current="page" onClick={scrollToTop}>Home</Link>
                                        </li>
                                        <li>
                                        <Link to="/about" className="footerlink" onClick={scrollToTop}>About Us</Link>
                                        </li>
                                        <li>
                                        <Link to="/package/himachal" className="footerlink" onClick={scrollToTop}>Himachal</Link>
                                            </li>
                                        <li>
                                        <Link to="/package/leh_ladakh" className="footerlink" onClick={scrollToTop}>Leh-Ladakh</Link>
                                            </li>
                                        <li>
                                        <Link to="/package/kashmir" className="footerlink" onClick={scrollToTop}>Kashmir</Link>
                                        </li>
                                        <li>
                                        <Link to="/package/home_stay" className="footerlink" onClick={scrollToTop}>Our Home Stay</Link>
                                        </li>
                                        <li>
                                        <Link to="/car-rental" className="footerlink" onClick={scrollToTop}>Car Rental</Link>
                                        </li>
                                        <li>
                                        <Link to="/term-conditions" className="footerlink" onClick={scrollToTop}>terms & conditions</Link>
                                        </li>
                                        <li>
                                        </li>
                                        <li><Link to="/#home-contactus" className="nav-link" onClick={(e)=>scrollToContact(e)}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-right-section">
                                <div className="footer-title">
                                    {props.data.pgFooterHD}
                                </div>
                                <div className="section-data">
                                    {props.data.pgFooterDC}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-coppy-right-section">
                    <div className="container">
                        <span>© 2024 bswn.com. All Right Reserved. Powered by BSWN.</span>
                    </div>

                </div>
            </div>
        </footer>
        <div className='whatsappchat'>
            <a target="_blank" href="https://wa.me/919459990595?text=I'm%20interested%20in%20your%20RH%20Adventure"><img src={whatsappchat} alt='chat icon'/></a>
        </div>
        </>
    );
    }
export default Footer;