import React from "react";
function Banner() {
    return (
        <section className="banner-section">
            <div className="home-banner-image hero">
            </div>
        </section>
    );
}
export default Banner;
