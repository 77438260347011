import React,{useState, useRef} from 'react';

import {gbData} from "../globalVarbs";

import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { InputText } from 'primereact/inputtext';
import { InputTextarea  } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function RequestCall( props ) {

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    // toast and formik functions
        const toast = useRef(null);

        const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };

        const isFormFieldInvalid = ( name ) => !!( formik.touched[ name ] && formik.errors[ name ] );

        const getFormErrorMessage = ( name ) => {
            return isFormFieldInvalid( name ) ? <small className="p-error">{ formik.errors[ name ] }</small> : <small className="p-error">&nbsp;</small>;
        };
    // end

    // formik validation and form submit
        const formik = useFormik({
        initialValues: {
            rqstName: '',
            rqstNmbr: '',
            rqstMsg: ''
        },
        validate: ( data ) => {
            let errors = {};

            if ( !data.rqstName ) {
                errors.rqstName = 'Name is required.';
            }

            if ( !data.rqstNmbr ) {
                errors.rqstNmbr = 'Phone Number is required.';
            }

            return errors;
        },
        onSubmit: ( data ) => {
            // return
            // data && showT(data);
            // console.log(data);
            // return;
            setLoading( true );

            if( !data.rqstMsg ){
                data.rqstMsg = "No Message Sent";
            }

            fetch( gbData.API_URL + '/send-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "name": data.rqstName,
                    "number": data.rqstNmbr,
                    "message": data.rqstMsg,
                    "sendType":"callbackRequest",
                    "email":"no_email",
                    "packageName":props.packageName
                }),
            })
            .then( response => {
                if ( !response.ok ) {
                    showT('error', 'Failed', 'Network response was not ok' );
                    throw new Error(  );
                    setLoading(false);
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                // console.log( 'ApiResponse:', ApiResponse );
                if ( ApiResponse.success == 0 ) {
                    showT( 'error', 'Failed', ApiResponse.message );
                    setLoading( false );
                    return;
                }

                setTimeout( function(){
                    showT( 'success', 'Success', ApiResponse.message );
                }, 100);
                
                formik.resetForm();
                setVisible(false);
                setLoading(false);
                // fetchData();
            })
            .catch(error => {
                showT( 'error', 'Failed', error );
                console.error('Error:', error);
            });                
        }
    });
    // end

    return(
        <PrimeReactProvider>
        <Toast ref={toast} position="top-center" />
        <Dialog header="REQUEST A CALL FORM" className="request-form-header" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <div className="m-0 requestacall-form">
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2 add-pkg-pop-form">
                <div className="login-field-wrapper">
                    <div className="login-field">
                        <span className="p-float-labell">
                            <InputText
                                placeholder="Your - Name"
                                name="rqstName"
                                value={formik.values.rqstName}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstName', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstName') })}
                            />
                            {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                            {getFormErrorMessage('rqstName')}
                        </span>

                    </div>
                    <div className="login-field">
                        <span className="p-float-labell">
                            <InputText
                                placeholder="Your number"
                                keyfilter="num"
                                name="rqstNmbr"
                                value={formik.values.rqstNmbr}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstNmbr', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstNmbr') })}
                            />
                            {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                            {getFormErrorMessage('rqstNmbr')}
                        </span>

                    </div>
                    <div className="login-field">
                        <span className="p-float-labell">
                            <InputTextarea
                                placeholder="Any Message"
                                name="rqstMsg"
                                value={formik.values.rqstMsg}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstMsg', e.target.value);
                                }}
                                className={classNames('w-full mb-3', { 'p-invalid': isFormFieldInvalid('rqstMsg') })}
                            />
                            {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                            {getFormErrorMessage('rqstMsg')}
                        </span>

                    </div>
                    <div className="login-field request-button">
                        <Button type="submit" loading={loading} label="Send Callback Request" />
                    </div>
                </div>
            </form>
            </div>
        </Dialog>
        {/* <a href="#" className="secondary-button" onClick={() => setVisible(true)}>
            Request callback comp
        </a> */}
        <Button loading={loading} label="Request callback" onClick={() => setVisible(true)} />
        </PrimeReactProvider>
    )
}

