import tick_icon from '../images/icons/tick2-1.png';
import about_image from '../images/about-image.png';
import { Link, useNavigate } from 'react-router-dom';
function About_home() {
    return (
        <section className="home-about-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="home-about-image">
                            <img src={about_image} alt="about-image" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="home-about-content">
                            <h2>We’re Most Trusted Travel</h2>
                            <h3>Partner Aroud The World</h3>
                            <p>The Success story of RH Adventures and Holidays commenced in the year 2017 with a vision to establish a company which caters to the demand of customers in tours and travel industry with customer oriented approach.  It has been registered under companies-registrar vide companies act. The company cultivates a culture of innovation and excels at introducing new concepts to the market.
                            </p>
                            <div className="home-about-service">
                                <ul>
                                    <li><span><img src={tick_icon} /></span> Tour packages</li>
                                    <li><span><img src={tick_icon} /></span>Home stay</li>
                                    <li><span><img src={tick_icon} /></span>Hotel Bookings </li>
                                    <li><span><img src={tick_icon} /></span>Trekking & Camping</li>
                                </ul>
                            </div>
                            <div className="button-section">
                            <Link to="/about" className="primary-button">Learn More Us</Link>
                                {/* <a href="#" className="primary-button">Learn More Us</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About_home;