import React from "react";
function ListMap(props){
    return(
        <div className="sp-map-section sp-data-box mt-40">
        <div className="booking-title">
            <div className="sp-title heading-under-line">
                View On Map
            </div>
        </div>
        <div className="sp-view-map">
            <iframe
                src={props.LData.information.mapLinkNF}
                width="100%" 
                height="350" 
                style={{ border:"0" }} 
                allowFullScreen="" 
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">

                </iframe>
        </div>
    </div>
    );
}
export default ListMap;