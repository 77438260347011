import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import testi1 from '../images/testi-1.jpg';
import active_star from '../images/icons/active-star.png';
import inactive_star from '../images/icons/inactive-star.png';
import testi2 from '../images/testi-2.jpg';
import {gbData} from "../../globalVarbs";

import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";

function ListReview( props ) {
    const options = {
        loop: false,
        center: true,
        margin: 10,
        autoplay: true,
        dots: true,
        autoplayTimeout: 5000,
        smartSpeed: 450,
        autoplayHoverPause: true,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 2
            }
        }
    };

    function reviewStars( stars ) {
      
        const stars_rev = [];
        for (let i = 0; i < stars; i++) {
          stars_rev.push(
            <img key={i} src={active_star} />
          );
        }
      
        return (
          <>{stars_rev}</>
        );
      }

    const ownReviewItems = props.LData.review.map((item,index) => (
        <div key={index} className='item'>
            <div className="single-review">
                <div className="review-client-details">
                    <div className="review-client-img">
                        <div className="user-profile">
                            <img src={`${gbData.LV_URL}${item.review_image}`} />
                        </div>
                    </div>
                    <div className="review-client-name-rating">
                        <div className="review-client-name">
                            {item.review_title}
                        </div>
                        <div className="review-clent-rating">                            
                            {reviewStars(item.review_star)}
                        </div>
                    </div>
                </div>
                <div className="review-content">
                    <p>{item.review_text}</p>
                </div>
            </div>
        </div>
            
    ))

    return (
        <PrimeReactProvider>
            { 
                ( props.LData.review.length > 0 )?
                    <div className="single-package-tour-review-section sp-data-box mt-40">
                        <div className="sp-title heading-under-line">
                            Reviews
                        </div>
                        <div className="review-slider">                            
                            <OwlCarousel className='owl-theme' {...options}>
                                {ownReviewItems}
                            </OwlCarousel>                            
                        </div>
                    </div>
                :
                ""
            }
        </PrimeReactProvider>
    );
}
export default ListReview;