import * as React from "react";
import oops_img from '../images/Oops-img.png';
import { Link } from "react-router-dom";

export default function ErrorPage(){
    return(
        <div>
            <div className="notfound-page-content">
                <div className="container">
                    <div className="notfound-image">
                        <img src={oops_img} alt="not-found-page"/>
                    </div>
                    <div className="notfound-title"><h2>404 Page Not Found</h2></div>
                    <div className="notfound-description">
                        <p>The page you are looking for might have been removed</p>
                        <p>had its name changed or is temporarily unavailable.</p>
                        <div className="button-section"><Link to="/" className="primary-button">Go To Home Page</Link></div>
                    </div>
                </div>
            </div>
        </div>
    )
}