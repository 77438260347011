import React from 'react';
import {useNavigate} from 'react-router-dom';
import logo from './images/Logo.png';
import email_png from './images/icons/email-png.png';
import phont_png from './images/icons/phone-top-1.png'
import facebook_fill from './images/icons/ri_facebook-fill.png';
import insta_fill from './images/icons/lets-icons_insta-fill.png';
import youtube_fill from './images/icons/fa_youtube.png';
import { Link } from 'react-router-dom';

function Header( props ) {

  const navigate = useNavigate();
  const isHomePageHDR = ()=> {
    return window.location.pathname === '/';
  }  

  const scrollToContact = (e) => {
    e.preventDefault();

    navigate('/');
    setTimeout( ()=>{
      const element = document.getElementById("home-contactus");
      if(element){      
        element.scrollIntoView();
        // window.scrollTo(0, document.body.scrollHeight);
      }
    },10)
    
  }

  return (
      <header className={isHomePageHDR()?'is-home-page':'inner-page'}>        
        <div className="container">
          <div className="pre-header">
            <div className="pre-header-content">
              <div className="pre-header-left-section">
                <div className="header-email-section header-contact-sec">
                  <div className="header-email-icon header-icon-sec">
                    <img src={email_png} alt="email-top" />
                  </div>
                  <div className="header-email-data header-data-sec">
                    <a href="mailto:rockinghimachladvantureandholiday@gmail.com">{props.data.pgHeaderEmail}</a>
                  </div>
                 </div>
                 <div className="header-phone-section header-contact-sec">
                    <div className="header-phone-icon header-icon-sec">
                      <img src={phont_png} alt='top-phone-icon' />
                    </div>
                    <div className="header-phone-data header-data-sec">
                      <a href="tel:{props.data.pgHeaderPhone}">{props.data.pgHeaderPhone}</a>
                    </div>
                  </div>
               </div>
               <div className="pre-header-right-section">
                  <div className="top-social-icons">
                    <div className="top-single-social"><a target='_blank' href={props.data.pgHeaderFB}><img src={facebook_fill} alt="facebook-icon" /></a>
                    </div>
                    <div className="top-single-social"><a target='_blank' href={props.data.pgHeaderInsta}><img
                      src={insta_fill} alt='instagram-icon' /></a></div>
                    <div className="top-single-social"><a target='_blank' href={props.data.pgHeaderYT}><img src={youtube_fill} alt='youtube-icon' /></a></div>
                  </div>
                </div>
            </div>
            <div className="header-bar">
              <div className="header-logo-section">
                <Link to="/"><img src={logo} alt='Rh-logo' /></Link>
              </div>
              <div className="header-menu-bar-section">
                <nav className="navbar navbar-expand-lg">
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                        <Link to="/" className="nav-link" aria-current="page">Home</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link">About Us</Link>
                        {/* <a className="nav-link" href="#">About us</a> */}
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Himachal</a> */}
                        <Link to="/package/himachal" className="nav-link">Himachal</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Leh-Ladakh</a> */}
                        <Link to="/package/leh_ladakh" className="nav-link">Leh-Ladakh</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Kashmir</a> */}
                        <Link to="/package/kashmir" className="nav-link">Kashmir</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Our Home Stay</a> */}
                        <Link to="/package/home_stay" className="nav-link">Our Home Stay</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Car Rental</a> */}
                        <Link to="/car-rental" className="nav-link">Car Rental</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Contact Us</a> */}
                        <Link className="nav-link" onClick={(e)=>scrollToContact(e)}>Contact Us</Link>  
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}

export default Header;
