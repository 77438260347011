import React from "react";
import { PrimeReactProvider } from 'primereact/api';

function TermAndCondition() {

    const scrolltotop = () => {
        window.location.hash = 'top';
    }

    return (
        <PrimeReactProvider> 
            <div className="inner-pages-content">
                <div className="inner-banner-title about-title-bg">
                    <div className="inner-page-title">Terms & Conditions</div>
                </div>
                <div className="container">
                    <p>
                        <ul>
                            <li>All correspondence(s) in respect of Tours / Travel Service bookings should be addressed to RH Adventures & Holidays.</li>
                            <li>There is No Refund for unutilized Service.</li>
                            <li>Coach tickets, ferry tickets, Internal flights, Sightseeing are Non – Refundable , once issued.</li>
                            <li>Rate of Exchange will be considered on the day when you make final payment.</li>
                            <li>If you can’t reach on time then it will be consider as no show & no refund. In SIC & Private Transfer basis driver will wait only for 10 min.</li>
                            <li>Vehicle is booked as per itinerary only. In case of extra kms or reroute of itinerary, shall be charged additionally.</li>
                            <li>Cooking anything in the hotel is strictly prohibited, in case of not following the rules, heavy penalties are levied to the passenger.</li>
                            <li>We as an agent or Hotel Team will not be responsible for loss of any Valuables/Passports anywhere. During Visa Process or On Tour Or at the airport incase of passport damage we shall not be responsible for any consequences.</li>
                            <li>Please read emails carefully and understand the contents and prices. All the email conversations will be also part of your contract with us once you make the booking.</li>
                            <li>Please match all your travel voucher and flight ticket to your original passport and is there any mismatch then update before 1 week of travel date. Otherwise it’s not responsibility of travel agent.</li>
                            <li>Please take care of the belongings before leaving the car or Hotels. Company is not responsible for any loss.</li>
                            <li>As per the directive given by the Government of India, all Indian/Foreign Nationals are required to provide proper proof of identification upon check-in. valid identifications documents are: Passport & Visa together, Voters Id card, Current Drivers License. All the documents must include a photograph.</li>
                            <li>Hotel / camps or any kind of accommodation can deny stay if valid id proof is not provided. Company is not responsible for any ID proof related issues.</li>
                            <li>For extra adult many hotels provide extra mattress also instead of extra bed. Kindly inform us in advance if you prefer only extra bed instead of extra mattress.</li>
                            <li>It is presumed that the tourist is medically fit to undertake the tour. If you have certain kind of allergy (especially for food) or any serious health issues kindly inform us in advance.</li>
                            <li>Many small hotels / resorts do not have lift so kindly inform us in advance if you are not comfortable with stairs.</li>
                            <li>Generally hotel check in time is12:00 Noon and checkout time is 11:00 AM. Confirm early check in or late checkout will be chargeable if not included in cost. Kindly contact hotel directly if you want complimentary early check in or late checkout.</li>
                            <li>Many hotels charge extra for room heater or wi-fi. Normal package does not include this so in that case these charges should be paid at hotels.</li>
                            <li>If meals are included in package it will be served at set menu basis in a buffet menu / fixed / meal credit. Any items which are not in their in ideal Buffet / Fixed menu will be charged extra at the time of consumption. Hotels decide the menu and options of items for menu. Some hotels having Meal credit policy instead of Buffet or Fixed Menu during low occupancy in hotel – This meal credit amount is decided by hotel management – if bills goes above the given meal credit amount then extra amount should be paid directly at respective hotel. Room service for the food or packing is charged extra at many hotels.</li>
                            <li>Breakfast timing 08AM to 10AM & Dinner timing 08PM to 10PM (about the timing you can contact hotel management before)</li>
                            <li>Due to any reason if Vehicle breakdown due to technical reasons we will try our best to arrange another vehicle as soon as possible for the major problems. For minor problems which will not take much time vehicle will be repaired. Kindly cooperate.</li>
                            <li>We shall not be responsible for any delays & alterations in the program or expenses incurred directly or indirectly due to natural hazards, flight cancellations, accident, breakdown of transport, weather, sickness, landslides, closures/blockades due to political unrest or any such incidents.</li>
                            <li>AC will not work in all hill areas or stopped / parked vehicle.</li>
                            <li>Pick up and drop point will be one only (If multiple pickups and drop please mentions email conversation before booking)</li>
                            <li>In case you want multiple visit to the some particular place kindly mention in advance because some places are having very high charges of parking.</li>
                            <li>Tour destination / route cannot change without prior notice (Amended route and tour Destination rates may be change)</li>
                            <li>At many of the tourist destination / hill station – some of the road / shopping market / mall road have “NO ENTRY” for all / some bigger tourist vehicles depends on destination along with time restrictions also. In this kind of situation guest have to manage another available mode of transport with an additional cost and on direct payment basis, this cost is not included in ideal package inclusion.</li>


                        </ul>
                    </p>
                    <p>
                        <b>Payment Policy</b>
                        <ul>
                            <li>20% of your package cost is required to book any package as token amount.</li>
                            <li>30% of your package cost is required to pay after you get your Hotel Confirmation Vouchers.</li>
                            <li>Rest 50% can be paid on your arrival.</li>
                        </ul>

                    </p>
                    <p>
                        <b>Cancellation & Refund Policy</b>
                        <ul>
                            <li>30 days prior to departure of the tour, advance amount is non-refundable, advance will be charged.</li>
                            <li>30 days to 15 days prior to departure of the tour 50% of total tour cost.</li>
                            <li>14 days to 07 days prior to departure of the tour 75% of total tour cost.</li>
                            <li>07 days to 01 days prior to departure of the tour 100% of total tour cost.</li>
                            <li>There will be no refund for NO SHOWS.</li>
                            <li>All Disputes will be settled at Kullu Court.</li>
                        </ul>
                    </p>
                </div>        
            </div>
            {scrolltotop()}
        </PrimeReactProvider> 
        
    );
    
}
export default TermAndCondition;