import React from "react";
import Banner from './Banner';
import About_home from './About_home';
import Popular_packages_home from "./Popular_packeges_home";
import Why_choose_section from "./Why_choose_section";
import Favorite_packages from "./Favorite_packages";
import Contact_us from "./Contact_us";
function Home(){
    return(
        <div className="home-page-wrapper">
            <Banner/>
            <About_home/>
            <Popular_packages_home/>
            <Why_choose_section/>
            <Favorite_packages/>
            <Contact_us/>
        </div>
    );
}
export default Home;