import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {gbData} from "../../globalVarbs";
import SkeletonStructure from '../HomeComponents/SkeletonStructure';
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";

import ListInclude_section from "./ListInclude_section";
import ListInqueryForm from "./ListInqueryForm";
import ListMap from "./ListMap_section";
import ListOverview from "./ListOverview_section";
import ListPriceBox from "./ListPriceBox_section";
import ListReview from "./ListReview";
import ListTitle_section from "./ListTitle_section";
import ListTourPlan from "./ListTourPlan_section";
import SingleListGallery from "./SingleListGallery";
import ErrorPage from "../Pages/ErrorPage";



export default function SingleList() {

    let { list_slug } = useParams();
    const [listData, setListData] = useState([]);
    const [ errorPage, seterrorPage ] = useState( false );
    const [ renderPage, setrenderPage ] = useState( false); 
    
    const fetchsingleListData = () => {
        fetch( gbData.API_URL+'/get-list-data', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "list_slug":list_slug
            }),
        })
        .then( response => {
            if( !response.ok ){
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            
            setrenderPage(true)
            // Process the newly created user data
            // console.log('ApiResponse:', ApiResponse);
            if( ApiResponse.success == 0 ){
                seterrorPage(true)
                return;
            }

            setListData( ApiResponse.data );   
            

        })
        .catch(error => {
            // console.error('Error:', error);
        });   
    }

    useEffect(() =>{
        fetchsingleListData();
    },[])

    return (        
        <PrimeReactProvider>    
            {
                (renderPage)?
                        (!errorPage)?
                            <section className="inner-pages-content single-package-page">
                                <div className="container">
                                    <SingleListGallery LData={listData} />
                                    <div className="single-package-data">
                                        <div className="row">
                                            <div className="col-md-8 sp-maincontent">
                                                <div className="single-package-main">
                                                    <ListTitle_section LData={listData} />
                                                    <ListInclude_section LData={listData} />
                                                    <ListOverview LData={listData} />
                                                    <ListTourPlan LData={listData} />
                                                    <ListReview LData={listData} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 sp-sidebar">
                                                <div className="single-package-sidebar">
                                                    <ListPriceBox LData={listData} />
                                                    <ListInqueryForm LData={listData}/>
                                                    <ListMap LData={listData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        :
                        <ErrorPage />
                :
                <SkeletonStructure />
            }    
            
        </PrimeReactProvider>
    );
}
