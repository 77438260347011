import tick_icon from '../images/icons/tick2-1.png';
import mission_bg from '../images/mission-bg.png';
import tm1 from '../images/tm1.png';
import tm2 from '../images/tm2.png';
import tm3 from '../images/tm3.png';
import ri_facebook from '../images/icons/ri_facebook-fill.png';
import lets_icons_insta from '../images/icons/lets-icons_insta-fill.png';
import fa_youtube from '../images/icons/fa_youtube.png';
// import SkeletonStructure from '../HomeComponents/SkeletonStructure';
function About() {
    const scrolltotop = () => {
        window.location.hash = 'top';
    }
    return (
        <section className="inner-pages-content">
            {/* <SkeletonStructure/> */}
            <div className="inner-banner-title about-title-bg">
                <div className="inner-page-title">About us</div>
            </div>
            <div className="about-page-main-content">
                <div className="container">
                    <div className="about-page-main-text">
                        <h2>We’re Most Trusted Travel</h2>
                        <h3>Partner Aroud The World</h3>
                        <p>The Success story of RH Adventures and Holidays commenced in the year 2017 with a vision to establish a company which caters to the demand of customers in tours and travel industry with customer oriented approach.  It has been registered under companies-registrar vide companies act. The company cultivates a culture of innovation and excels at introducing new concepts to the market. Our team members have travelled extensively to numerous destinations, gaining unique insights knowledge of the Destinations and hence now recommending to our clients. Being passionate Travelers themselves, they combine an intuitive understanding with the customers as to serve them with the best suited tour package. Whether it is 24 hours a day, 7 days a week, or 365 days throughout the year, our staffs is totally dedicated towards their client thus making a magical connection with them.
                            Our holiday travel packages encompass everything the traveler needs planning the trip,booking travel tickets,getting a place to stay and guidance till you reaches the home. We arrange all tours both for FIT’s and GIT’s as well we have the expertise to handle students and corporate groups.</p>
                            <p>We know that you would come to us if we provide secure value for your money,share the savings with you and add further value to your experience.</p>
                            <p>Our service starts with offering necessary guidance to plan the vacation and ends with the end of the great holiday trip. It is our sole objective to make ensure customers are satisfied and confident in our abilities to meet their needs.</p>
                        <div className="home-about-service about-our-service">
                            <ul>
                                <li><span><img src={tick_icon} /></span> Tour packages</li>
                                <li><span><img src={tick_icon} /></span>Home stay </li>
                                <li><span><img src={tick_icon} /></span>Hotel Bookings</li>
                                <li><span><img src={tick_icon} /></span>Trekking & Camping</li>
                                <li><span><img src={tick_icon} /></span> Texi Services</li>
                                <li><span><img src={tick_icon} /></span>Flight. &. train. Booking </li>
                                <li><span><img src={tick_icon} /></span>Volvo booking</li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-who-we-are">
                <div className="who-we-are-image">
                    <img src={mission_bg} alt='mission-bg image' />
                </div>
                <div className="who-we-are-data">
                    <div className="who-we-are-title">
                        <h2>Who We Are</h2>
                        <h3>Compnay Mission And Vision</h3>
                    </div>
                    <div className="mission-vision-goals">
                        <div className="single-mission">
                            <div className="mission-box-title sp-title heading-under-line">
                                Our Mission
                            </div>
                            <div className="mission-content">
                                <p>our mission is to become the leading specialized travel company by planning customised vacations that create exceptional value for our customers.</p>
                            </div>
                        </div>
                        <div className="single-mission">
                            <div className="mission-box-title sp-title heading-under-line">
                                Our Vision
                            </div>
                            <div className="mission-content">
                                <p>We are an Innovative tour operater , renowed for our high quality service, responsible practices, social and environmental commitments. Our Vision to be continuously competitive in the Travel Industry by Continously developing new relationships and opportunities.</p>
                            </div>
                        </div>
                        <div className="single-mission">
                            <div className="mission-box-title sp-title heading-under-line">
                                Our Goal
                            </div>
                            <div className="mission-content">
                                <p>Our service starts with offering necessary guidance to plan the vacation and ends with the end of the great holiday trip.It is our sole objective to make ensure customers are satisfied and confident in our abilities to meet their needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="team-member">
                <div className="container">
                    <div className="team-sec-title">
                        <h2>Team Member</h2>
                        <h3>Meet Our Professional Team Members</h3>
                    </div>
                    <div className="team-members-boxes">
                        <div className="team-member-box">
                            <div className="team-member-profile-pic">
                                <img className="Profile-pic" src={tm1} alt='profile picture' />
                                <div className="team-member-social">
                                    <div className="tm-single-social">
                                        <a href="https://www.facebook.com/rittam.chand?mibextid=kFxxJD" target='_blank'>
                                            <img src={ri_facebook} />
                                        </a>
                                    </div>
                                    <div className="tm-single-social">
                                        <a href="https://www.instagram.com/travel_with_rittam?igsh=MXJsZ3Z1bzJveWtnZw==" target='_blank'>
                                            <img src={lets_icons_insta} />
                                        </a>
                                    </div>
                                    <div className="tm-single-social">
                                        <a href="https://www.youtube.com/@safar8866" target='_blank'>
                                            <img src={fa_youtube} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="team-details">
                                <div className="mamber-name">
                                    Rittam Thakur
                                </div>
                                <div className="member-designation">
                                    CEO & Founder
                                </div>
                            </div>
                        </div>
                        <div className="team-member-box">
                            <div className="team-member-profile-pic">
                                <img className="Profile-pic" src={tm2} alt='profile picture' />
                                <div className="team-member-social">
                                    {/* <div className="tm-single-social">
                                        <a href="#">
                                            <img src={ri_facebook} />
                                        </a>
                                    </div>
                                    <div className="tm-single-social">
                                        <a href="#">
                                            <img src={lets_icons_insta} />
                                        </a>
                                    </div>
                                    <div className="tm-single-social">
                                        <a href="#">
                                            <img src={fa_youtube} />
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="team-details">
                                <div className="mamber-name">
                                    Anjali Thakur
                                </div>
                                <div className="member-designation">
                                    Senior Sales Executive
                                </div>
                            </div>
                        </div>
                        <div className="team-member-box">
                            <div className="team-member-profile-pic">
                                <img className="Profile-pic" src={tm3} alt='profile picture' />
                                <div className="team-member-social">
                                    {/* <div className="tm-single-social">
                                        <a href="#">
                                            <img src={ri_facebook} />
                                        </a>
                                    </div>
                                    <div className="tm-single-social">
                                        <a href="#">
                                            <img src={lets_icons_insta} />
                                        </a>
                                    </div>
                                    <div className="tm-single-social">
                                        <a href="#">
                                            <img src={fa_youtube} />
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="team-details">
                                <div className="mamber-name">
                                    Yash Thakur
                                </div>
                                <div className="member-designation">
                                    Service & Operation Head
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {scrolltotop()}
        </section>
        
    );
    
}
export default About;