import React,{useState, useRef} from 'react';

import {gbData} from "../../globalVarbs";

import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { InputText } from 'primereact/inputtext';
import { InputTextarea  } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

import map_location from '../images/map-location -1.png';

function Contact_us(){
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    // toast and formik functions
        const toast = useRef(null);

        const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };

        const isFormFieldInvalid = ( name ) => !!( formik.touched[ name ] && formik.errors[ name ] );

        const getFormErrorMessage = ( name ) => {
            return isFormFieldInvalid( name ) ? <small className="p-error">{ formik.errors[ name ] }</small> : <small className="p-error">&nbsp;</small>;
        };
    // end

    // formik validation and form submit
    const formik = useFormik({
        initialValues: {
            rqstName: '',
            rqstNmbr: '',
            rqstEmail:'',
            rqstMsg: ''
        },
        validate: ( data ) => {
            let errors = {};

            if ( !data.rqstName ) {
                errors.rqstName = 'Name is required.';
            }

            if ( !data.rqstNmbr ) {
                errors.rqstNmbr = 'Phone Number is required.';
            }

            if ( !data.rqstEmail ) {
                errors.rqstEmail = 'Email is required.';
            }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.rqstEmail)) {
                errors.rqstEmail = 'Invalid email address';           
            }

            if ( !data.rqstMsg ) {
                errors.rqstMsg = 'Message is required.';
            }

            return errors;
        },
        onSubmit: ( data ) => {
            setLoading( true );

            // if( !data.rqstMsg ){
            //     data.rqstMsg = "No Message Sent";
            // }

            fetch( gbData.API_URL + '/send-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "name": data.rqstName,
                    "number": data.rqstNmbr,
                    "message": data.rqstMsg,
                    "sendType":"home_contact",
                    "email":data.rqstEmail,
                    "packageName":"no package"
                }),
            })
            .then( response => {
                if ( !response.ok ) {
                    showT('error', 'Failed', 'Network response was not ok' );
                    throw new Error(  );
                    setLoading(false);
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                // console.log( 'ApiResponse:', ApiResponse );
                if ( ApiResponse.success == 0 ) {
                    showT( 'error', 'Failed', ApiResponse.message );
                    setLoading( false );
                    return;
                }

                setTimeout( function(){
                    showT( 'success', 'Success', ApiResponse.message );
                }, 100);
                
                formik.resetForm();
                setVisible(false);
                setLoading(false);
                // fetchData();
            })
            .catch(error => {
                showT( 'error', 'Failed', error );
                console.error('Error:', error);
            });  
                         
        }
    });
    // end

    return(
        <PrimeReactProvider>
            <Toast ref={toast} position="top-center" />
            <section className="contact-us" id='home-contactus'>
                <div className="container">
                    <div className="contact-title">
                        <h2>Contact Us</h2>
                        <h3>Ready to Travel us for better advanture</h3>
                    </div>
                    <div className="contact-data-section">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-map-section">
                                    <img src={map_location} alt='map-location-icon' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-form">
                                    <form onSubmit={formik.handleSubmit} method="post">
                                        <div className="form-title">
                                            Get In Touch
                                        </div>
                                        <div className="formfield">
                                            <InputText
                                                placeholder="Enter Name"
                                                name="rqstName"
                                                value={formik.values.rqstName}
                                                onChange={(e) => {
                                                    formik.setFieldValue('rqstName', e.target.value);
                                                }}
                                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstName') })}
                                            />
                                            {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                            {getFormErrorMessage('rqstName')}
                                        </div>
                                        <div className="formfield">
                                            <InputText
                                                placeholder="Enter Email"
                                                keyfilter="email"
                                                name="rqstEmail"
                                                value={formik.values.rqstEmail}
                                                onChange={(e) => {
                                                    formik.setFieldValue('rqstEmail', e.target.value);
                                                }}
                                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstEmail') })}
                                            />
                                            {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                            {getFormErrorMessage('rqstEmail')}
                                        </div>
                                        <div className="formfield">
                                            <InputText
                                                placeholder="Enter Phone"
                                                keyfilter="num"
                                                name="rqstNmbr"
                                                value={formik.values.rqstNmbr}
                                                onChange={(e) => {
                                                    formik.setFieldValue('rqstNmbr', e.target.value);
                                                }}
                                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstNmbr') })}
                                            />
                                            {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                            {getFormErrorMessage('rqstNmbr')}
                                        </div>
                                        <div className="formfield">
                                                <InputTextarea
                                                    placeholder="Message"
                                                    rows={10} 
                                                    cols={30}
                                                    name="rqstMsg"
                                                    id="message"
                                                    value={formik.values.rqstMsg}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('rqstMsg', e.target.value);
                                                    }}
                                                    className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstMsg') })}
                                                />
                                                {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                                {getFormErrorMessage('rqstMsg')}
                                        </div>
                                        <div className="form-button">
                                            <Button type="submit" loading={loading} label="Submit" className='primary-button' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </PrimeReactProvider>
    );
}
export default Contact_us;