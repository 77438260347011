function ListTourPlan( props ){

    const tourPlanData = props.LData.tp.map((item,index) => (
        
        <div key={index} className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${index}`} aria-expanded="false"
                    aria-controls={`#collapse-${index}`}>
                    <span className="sp-day">{item.tp_day}</span> {item.tp_title}
                </button>
            </h2>
            <div id={`collapse-${index}`} className="accordion-collapse collapse"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    {item.tp_detail}
                </div>
            </div>
        </div>
            
    ))

    return(
        <div className="single-package-tour-plan-section sp-data-box mt-40">
            <div className="sp-title heading-under-line">
                Tour Plan
            </div>
            <div className="sp-tour-plan">
                {/* <!-- ----------------------------------------accordion----------------------------------------- --> */}
                <div className="accordion" id="accordionExample">
                    {tourPlanData}
                </div>
                {/* <!-- ----------------------------------------end accordion---------------------------------------------- --> */}
            </div>
        </div>
    );
}
export default ListTourPlan;