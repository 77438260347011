import package_main_image from '../images/packege-1-img.png';
import mingcute_time from '../images/icons/mingcute_time-fill.png';
import mdi_users from '../images/icons/mdi_users.png';
import subway_call from '../images/icons/subway_call.png';
import React, { useState, useEffect, useRef } from "react";
import {gbData} from "../../globalVarbs";
import { Link } from 'react-router-dom';
import RequestCall from '../RequestCall';
import SkeletonStructure from '../HomeComponents/SkeletonStructure';

function Popular_packages_home() {

    const [ ppData, setPPData ] = useState( [] );
    let card_image = "";
    let fetch_tag_slug = 'popular_tour_packeges';
    
    const fetchPopularPackageData = () => {
        fetch( gbData.API_URL+'/get-package-list-from-categ-or-tag', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "get_type":'tag',
                "get_type_slug":fetch_tag_slug,
                "record_per_row":6,
                "record_page":0
            }),
        })
        .then( response => {
            if( !response.ok ){
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            // Process the newly created user data
            console.log('ApiResponse:', ApiResponse);
            if( ApiResponse.success == 0 ){
                return;
            }

            setPPData( ApiResponse.data );   
            // console.warn(ApiResponse.data); 
            // console.warn(ppData); 

        })
        .catch(error => {
            // console.error('Error:', error);
        });   
    }

    const ppArrayMap = ppData.map((item,index) => (

        <div className="col-md-4" key={index}>
            <div className="listing-single-packege">
                <div className="listing-packege-image">
                    <Link to={`/${item.packg_ls_slug}`} >
                    {/* <a href={`/${item.packg_ls_slug}`}> */}
                        {
                            (item.card_image)?
                                <img src={`${gbData.LV_URL}${item.card_image}`}  alt="package-main-image"/>
                            :
                            <img src={package_main_image}  alt="package-main-image"/>
                        }
                    </Link>
                    {/* </a> */}
                </div>
                <div className="listing-packege-details">
                    <div className="listing-packege-title">
                        {item.packg_ls_name}
                    </div>
                    <div className="listing-days-and-guests">
                        <div className="listing-numberofdays">
                            <div className="days-icons">
                            <img src={mingcute_time} alt='time-icon'/>
                            </div>
                            <div className="days-night">
                                {item.day_night}
                            </div>
                        </div>
                        <div className="listing-numberofuser">
                            <div className="guesticon">
                            <img src={mdi_users} alt='number-of-user-icon'/>
                            </div>
                            <div className="numberofuser">
                                {item.personsNF}
                            </div>
                        </div>
                    </div>
                    <div className="listing-packege-price-section">
                        <div className="act-price">
                            INR {item.pricetNF}
                        </div>
                        <div className="discounted-price">
                            INR {item.discountedPricetNF}
                        </div>
                    </div>
                    <div className="listing-packege-button-section">
                        <div className="packege-call-button">
                            <a href={`tel:${item.callNumber}`} className="packege-primary-button">
                                <span><img src={subway_call} /></span>Call Now
                            </a>
                        </div>
                        <div className="packege-request-call-button">                            
                            <RequestCall packageName={item.packg_ls_name} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
    ));

    useEffect(() =>{
        fetchPopularPackageData();
    },[])

    return (
        <>
            <section className="popular-packeges">
                <div className="container">
                    <div className="packeget-heading">
                        <h2>Popular Tour Packeges</h2>
                        <h3>Choose Your Packeges</h3>
                    </div>
                    <div className="all-packeges">
                        <div className="row">
                            {
                                ( ppData.length > 0 ) ?
                                ppArrayMap
                                :
                                <SkeletonStructure />
                            }
                        </div>
                    </div>
                    <div className="packege-view-all-button">
                        {/* <a href="/tag/{fetch_tag_slug}" className="primary-button">View All</a> */}
                        <Link to={`tag/${fetch_tag_slug}`} className="nav-link primary-button">View All</Link>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Popular_packages_home;
