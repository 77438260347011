import packege_1 from '../images/packege-1-img.png';
import mingcute_time from '../images/icons/mingcute_time-fill.png';
import subway_call from '../images/icons/subway_call.png';
import mdi_users from '../images/icons/mdi_users.png';

import React, { useState, useEffect, useRef } from "react";
import {gbData} from "../../globalVarbs";
import { useParams, Link } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import "primeflex/primeflex.css";
import ErrorPage from '../Pages/ErrorPage';
import RequestCall from '../RequestCall';
import SkeletonStructure from '../HomeComponents/SkeletonStructure';

export default function TagLists(){
    
    let { tag_slug } = useParams();
    const [categPckgesData, settagPckgesData] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(6);
    const [totalRecords, settotalRecords] = useState("");
    const [headingTitle, setheadingTitle] = useState("");
    const [errorPage, seterrorPage] = useState(false);
    const [renderPage, setrenderPage] = useState(false);

    const fetchCategoryPackages = (record_per_row=6, record_page=0) => {
        fetch( gbData.API_URL+'/get-package-list-from-categ-or-tag', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "get_type":'tag',
                "get_type_slug":tag_slug,
                "record_per_row":record_per_row,
                "record_page":record_page*record_per_row
            }),
        })
        .then( response => {
            if( !response.ok ){
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            setrenderPage(true)
            // Process the newly created user data
            // console.log('ApiResponse:', ApiResponse);
            if( ApiResponse.success == 0 ){
                seterrorPage(true)
                return;
            }
            
            settagPckgesData( ApiResponse.data );   
            settotalRecords(ApiResponse.extraInformation.total_records);
            setheadingTitle(ApiResponse.extraInformation.name)
            // console.warn(ApiResponse.data); 
            console.warn(categPckgesData); 
            

        })
        .catch(error => {
            // console.error('Error:', error);
        });   
    }

    const categPckgesArrayMap = categPckgesData.map((item,index) => (

        <div className="col-md-4" key={index}>
            <div className="listing-single-packege">
                <div className="listing-packege-image">
                    <Link to={`/${item.packg_ls_slug}`} >
                    {/* <a href={`/${item.packg_ls_slug}`}> */}
                        {
                            (item.card_image)?
                                <img src={`${gbData.LV_URL}${item.card_image}`}  alt="package-main-image"/>
                            :
                            <img src={packege_1}  alt="package-main-image"/>
                        }
                        </Link>
                    {/* </a> */}
                </div>
                <div className="listing-packege-details">
                    <div className="listing-packege-title">
                        {item.packg_ls_name}
                    </div>
                    <div className="listing-days-and-guests">
                        <div className="listing-numberofdays">
                            <div className="days-icons">
                            <img src={mingcute_time} alt='time-icon'/>
                            </div>
                            <div className="days-night">
                                {item.day_night}
                            </div>
                        </div>
                        <div className="listing-numberofuser">
                            <div className="guesticon">
                            <img src={mdi_users} alt='number-of-user-icon'/>
                            </div>
                            <div className="numberofuser">
                                {item.personsNF}
                            </div>
                        </div>
                    </div>
                    <div className="listing-packege-price-section">
                        <div className="act-price">
                            INR {item.pricetNF}
                        </div>
                        <div className="discounted-price">
                            INR {item.discountedPricetNF}
                        </div>
                    </div>
                    <div className="listing-packege-button-section">
                        <div className="packege-call-button">
                            <a href={`tel:${item.callNumber}`} className="packege-primary-button">
                                <span><img src={subway_call} /></span>Call Now
                            </a>
                        </div>
                        <div className="packege-request-call-button">
                            <RequestCall />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
    ));

    const onPageChange = (event) => {
        console.log(event)
        setrenderPage(false)
        setFirst(event.first);
        setRows(event.rows);
        fetchCategoryPackages(event.rows,event.page)
    };

    const scrolltotop = () => {
        window.location.hash = 'top';
    }

    useEffect(() =>{
        setrenderPage(false)
        fetchCategoryPackages();
        setFirst(0);
        setRows(rows);
    },[tag_slug])

    return(
        <>
            {
                (renderPage)?
                    (!errorPage)?
                        <section className="inner-pages-content">
                            <div className="inner-banner-title about-title-bg">
                                <div className="inner-page-title">{headingTitle} Packages</div>
                            </div>
                            <div className="category-packeges">
                                <div className="container">
                                    <div className="all-packeges">
                                        <div className="row">
                                            {
                                                ( categPckgesData.length > 0 ) ?
                                                    categPckgesArrayMap
                                                :
                                                ""
                                            }                        
                                        </div>
                                    </div>
                                    {
                                    (totalRecords)?
                                        <div className="pagination-section">
                                            <div className="card">
                                                <Paginator first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} />
                                            </div>
                                        </div>
                                    :
                                        ""
                                    }
                                    
                                </div>
                            </div>
                            {scrolltotop()}
                        </section>
                    :
                    <ErrorPage />
                :
                <SkeletonStructure />
            }
        </>
    );
}
