import include_icon1 from '../images/icons/king-size-bed.png';
import include_icon2 from '../images/icons/wellcome-bottle.png';
import include_icon3 from '../images/icons/loundry-service.png';
import include_icon4 from '../images/icons/pick-and-drop-service.png';
import include_icon5 from '../images/icons/meals-included.png';
import {gbData} from "../../globalVarbs";

function ListInclude_section( props ) {

    const includesinformation = props.LData.includes.map((item,index) => (        
        <li key={index}>
            <div className="single-include">
                <img src={`${gbData.LV_URL}${item.includeImage}`} alt='include-icon'/>
                <span>{item.includeText}</span>
            </div>
        </li>            
    ))
    return (
        <div className="single-package-include-section sp-data-box mt-40">
            <div className="sp-title heading-under-line">
                Included
            </div>
            <div className="sp-included-data sp-icon">
                <ul>
                    {includesinformation}                    
                </ul>
            </div>
        </div>

    );
}
export default ListInclude_section;