import why_icon1 from '../images/icons/why1.png';
import why_icon2 from '../images/icons/why2.png';
import why_icon3 from '../images/icons/why3.png';
function Why_choose_section() {
    return (
        <section className="why-choose-us-section">
            <div className="container">
                <div className="why-choose-title">
                    <h2>Why Choose Us</h2>
                </div>
                <div className="why-choose-content">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="whychoose-single">
                                <div className="why-choose-icone">
                                    <img src={why_icon1} alt='why-choose-us-icon'/>
                                </div>
                                <div className="why-choose-data">
                                    <div className="why-choose-title">
                                        Best Price Gurnatee
                                    </div>
                                    <div className="why-choose-info">
                                    Quality meets affordability with our best price guarantee
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="whychoose-single">
                                <div className="why-choose-icone">
                                    <img src={why_icon2} alt='why-choose-us-icon'/>
                                </div>
                                <div className="why-choose-data">
                                    <div className="why-choose-title">
                                        Easy & Quick Booking
                                    </div>
                                    <div className="why-choose-info">
                                    Simplicity at your fingertips - choose us for easy & quick booking
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="whychoose-single">
                                <div className="why-choose-icone">
                                    <img src={why_icon3} alt='why-choose-us-icon'/>
                                </div>
                                <div className="why-choose-data">
                                    <div className="why-choose-title">
                                        Best Tour Selection
                                    </div>
                                    <div className="why-choose-info">
                                    Unforgettable journeys start with our best tour selection
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
export default Why_choose_section;