import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function SkeletonStructure() {
    return (
        <SkeletonTheme baseColor="#F6FAFC" highlightColor="#21A2CF">
            <div className="inner-banner-title">
                <div className="inner-page-title"><Skeleton width={300} height={40} /></div>
            </div>
            <div className="row">
                <div className="col-md-4">
                <div className="listing-single-packege">
                    <div className="listing-packege-image">
                    <Skeleton height={400} />
                    </div>
                    <div className="listing-packege-details">
                        <div className="listing-packege-title">
                        <Skeleton />
                        </div>
                        <div className="listing-days-and-guests">
                            <div className="listing-numberofdays">
                                <div className="days-icons">
                                <Skeleton/>
                                </div>
                                <div className="days-night">
                                <Skeleton width={80} height={20}/>
                                </div>
                            </div>
                            <div className="listing-numberofuser">
                                <div className="guesticon">
                                <Skeleton />
                                </div>
                                <div className="numberofuser">
                                <Skeleton width={80} height={20}/>
                                </div>
                            </div>
                        </div>
                        <div className="listing-packege-price-section">
                            <div className="act-price">
                            <Skeleton width={80} height={20}/>
                            </div>
                            <div className="discounted-price">
                            <Skeleton width={80} height={20}/>
                            </div>
                        </div>
                        <div className="listing-packege-button-section">
                            <div className="packege-call-button">
                            <Skeleton width={200} height={40}/>
                            </div>
                            <div className="packege-request-call-button">
                            <Skeleton width={200} height={40}/>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
                <div className="col-md-4">
                    <div className="listing-single-packege">
                        <div className="listing-packege-image">
                        <Skeleton height={400} />
                        </div>
                        <div className="listing-packege-details">
                            <div className="listing-packege-title">
                            <Skeleton />
                            </div>
                            <div className="listing-days-and-guests">
                                <div className="listing-numberofdays">
                                    <div className="days-icons">
                                    <Skeleton/>
                                    </div>
                                    <div className="days-night">
                                    <Skeleton width={80} height={20}/>
                                    </div>
                                </div>
                                <div className="listing-numberofuser">
                                    <div className="guesticon">
                                    <Skeleton />
                                    </div>
                                    <div className="numberofuser">
                                    <Skeleton width={80} height={20}/>
                                    </div>
                                </div>
                            </div>
                            <div className="listing-packege-price-section">
                                <div className="act-price">
                                <Skeleton width={80} height={20}/>
                                </div>
                                <div className="discounted-price">
                                <Skeleton width={80} height={20}/>
                                </div>
                            </div>
                            <div className="listing-packege-button-section">
                                <div className="packege-call-button">
                                <Skeleton width={200} height={40}/>
                                </div>
                                <div className="packege-request-call-button">
                                <Skeleton width={200} height={40}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="listing-single-packege">
                        <div className="listing-packege-image">
                        <Skeleton height={400} />
                        </div>
                        <div className="listing-packege-details">
                            <div className="listing-packege-title">
                            <Skeleton />
                            </div>
                            <div className="listing-days-and-guests">
                                <div className="listing-numberofdays">
                                    <div className="days-icons">
                                    <Skeleton/>
                                    </div>
                                    <div className="days-night">
                                    <Skeleton width={80} height={20}/>
                                    </div>
                                </div>
                                <div className="listing-numberofuser">
                                    <div className="guesticon">
                                    <Skeleton />
                                    </div>
                                    <div className="numberofuser">
                                    <Skeleton width={80} height={20}/>
                                    </div>
                                </div>
                            </div>
                            <div className="listing-packege-price-section">
                                <div className="act-price">
                                <Skeleton width={80} height={20}/>
                                </div>
                                <div className="discounted-price">
                                <Skeleton width={80} height={20}/>
                                </div>
                            </div>
                            <div className="listing-packege-button-section">
                                <div className="packege-call-button">
                                <Skeleton width={200} height={40}/>
                                </div>
                                <div className="packege-request-call-button">
                                <Skeleton width={200} height={40}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
}
export default SkeletonStructure;