import main_image from '../images/img1.jpg';
import front_image1 from '../images/laida-1.jpg';
import front_image2 from '../images/img3.jpg';
import front_image3 from '../images/shangarh.jpg';
import front_image4 from '../images/sreulsaur.jpg';
import React, { useState } from "react";
import {gbData} from "../../globalVarbs";
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import FsLightbox from "fslightbox-react";
import galleryicon from '../images/image-gallery-icon.png';

function SingleListGallery( props ) {
    console.log(props.LData);
    console.log({gbData}); 
    let randint = "";
    const all_gallery = [];
    const [toggler, setToggler] = useState(false);

    const randomIntFromInterval = (min, max) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const bannerGalleryHtml = props.LData.gallery.map((item,index) => (
        (item.gallery_image_type=="main")?
             <div key={index} className="gallerybox" id="gallerybox-1"><img alt="image-gallery" src={`${gbData.LV_URL}${item.gallery_image}`} /></div>
        :
            (item.gallery_image_type=="firstfour")?
                <div key={index} className="gallerybox" id={`gallerybox-${item.id}`}>
                    <img alt="image-gallery" src={`${gbData.LV_URL}${item.gallery_image}`} />
                </div>     
            :
            ""
            
    ))

    // const bannerAllGalleryHtml = props.LData.gallery.map((item,index) => (
        
    //     // (item.gallery_image_type=="normal")?
    //     //     ( randomIntFromInterval(1, 2) == 1 )?
    //     //         <div className="col-lg-4 col-md-12 mb-4 mb-lg-0" key={index}>
    //     //             <img src={`${gbData.LV_URL}${item.gallery_image}`}
    //     //                 className="w-100 shadow-1-strong rounded mb-4" alt={item.list_slug} />
    //     //         </div>
    //     //     :
    //     //         <div className="col-lg-4 mb-4 mb-lg-0" key={index}>
    //     //             <img src={`${gbData.LV_URL}${item.gallery_image}`}
    //     //                 className="w-100 shadow-1-strong rounded mb-4" alt={item.list_slug} />
    //     //         </div>
    //     // :
    //     // "" 
    //     df      
    // ))

    function push_to_gallery( item_gallry ){
        all_gallery.push(
            gbData.LV_URL+item_gallry,
            
        );
        // alert(all_gallery);
    }

    function all_gallery_fn() {
      
        props.LData.gallery.map((item,index) => (
            (item.gallery_image_type=="normal")?
                push_to_gallery(item.gallery_image)
            :
            ""
        ))

        console.log(all_gallery);
        
        return (
          <>
            <FsLightbox
                toggler={toggler}
                sources={all_gallery}
                types={
                    [
                      ...new Array(all_gallery.length).fill('image'),
                    ]
                  }
            /> 
        </>
        );
      }

    return (
        <PrimeReactProvider>
            
            {
                (props.LData.gallery.length>1)?
                    
                    <div>
                        <div className="top-gallery-and-show-more-section">
                            <div className="bannerimage-galler">
                                {bannerGalleryHtml}
                            </div>
                            <div className="gallery-show-more">
                            {
                                props.LData.gallery.map((item,index) => (
                                    (item.gallery_image_type=="main")?
                                    <button className="imggallerybutton" onClick={() => setToggler(!toggler)}>
                                        <img src={galleryicon} alt='gallery icon' /> <span>Show All Photos</span>
                                    </button>
                                    :
                                    null   
                                ))
                            }

                            
                            </div>
                        </div>
                        {all_gallery_fn()}
                    </div>
                :
                ""
            }
            
        </PrimeReactProvider>
    );
}
export default SingleListGallery;