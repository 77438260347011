function ListOverview( props ) {
    return (
        <div className="single-package-overview-section sp-data-box mt-40">
            <div className="sp-title heading-under-line">
                OverView
            </div>
            <div className="sp-short-description">
                <p>
                    {props.LData.information.overviewNF}
                </p>
            </div>
        </div>
    );
}
export default ListOverview;