import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom/dist";
import Header from "./Header";
import Footer from "./Footer";
import {gbData} from "../globalVarbs"

export default function Layout(){
    useEffect(() => {
        document.title = "RHAdventures"
    }, [])

    const [hfData, setHFData] = useState([]);    

    const fetchHFData = async () => {
        await fetch( gbData.API_URL+'/get-hf-data', {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then( response => {
            if( !response.ok ){
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            // Process the newly created user data
            console.log('ApiResponse:', ApiResponse);
            if( ApiResponse.success == 0 ){
                return;
            }

            setHFData( ApiResponse.data );    

        })
        .catch(error => {
            console.error('Error:', error);
        });   
    }
    useEffect(() =>{
        fetchHFData();
    },[])
    return(
        
        <div className="page-main-wrapper">
            <Header data={hfData} />
                <Outlet />
            <Footer data={hfData} />
        </div>
    )
}