import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './CustomStyle.css';
import Home from "./Rh_componets/HomeComponents/Home";
import ErrorPage from "./Rh_componets/Pages/ErrorPage";
import Contact_us from "./Rh_componets/HomeComponents/Contact_us";
import Layout from "./Rh_componets/Layout";
import About from "./Rh_componets/Pages/About";
import CategoryLists from "./Rh_componets/CategoryLists/CategoryLists";
import SingleList from "./Rh_componets/SingleListPage/SingleList";
import TagLists from "./Rh_componets/TagLists/TagLists";
import TermAndCondition from "./Rh_componets/Pages/TermAndConditions";
import ReturnPolicy from "./Rh_componets/Pages/ReturnPolicy";
import CarRental from "./Rh_componets/Pages/CarRental";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/package/:category_slug",
        element: <CategoryLists />,
      },
      {
        path: "/tag/:tag_slug",
        element: <TagLists />,
      },
      {
        path: "/:list_slug",
        element: <SingleList />,
      },
      {
        path: "/term-conditions",
        element: <TermAndCondition/> ,
      },
      {
        path: "/return-policy",
        element: <ReturnPolicy/>,
      },
      {
        path: "/car-rental",
        element: <CarRental/> ,
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
