import React from "react";
import { PrimeReactProvider } from 'primereact/api';
function CarRental() {
    
    const scrolltotop = () => {
        window.location.hash = 'top';
    }

    return (
        <PrimeReactProvider>  
            <section className="inner-pages-content">
                <div className="inner-banner-title car-title-bg">
                    <div className="inner-page-title">CAR RENTAL</div>
                </div>
                <div className="simple-content-pages">
                    <div className="container">
                        <div className="tabledata">
                            <table className="infotable">
                                <tbody><tr>
                                    <td className="heading-type1">Day Wise Schedule</td>
                                    <td className="heading-type1">Alto</td>
                                    <td className="heading-type1">Swift Desire</td>
                                    <td className="heading-type1">Innova 6 Seater </td>
                                </tr>
                                    <tr>
                                        <td className="heading-type">Day 01 Pick up Aut bus stop to laida holidayhomestay.</td>
                                        <td rowspan="3"><b>Inr.3,500/</b></td>
                                        <td rowspan="3"><b>Inr.5,800/</b></td>
                                        <td rowspan="3"><b>Inr.6,500/</b></td>
                                    </tr>
                                    <tr>
                                        <td className="heading-type">Day 02 jalori, Raghupur fort</td>
                                    </tr>
                                    <tr>
                                        <td className="heading-type">Day 03 Shairopa and drop to aut bus stop</td>
                                    </tr>
                                </tbody></table>
                        </div>
                        <div className="tabledata mt-40">
                            <table className="infotable">
                                <tbody><tr>
                                    <td className="heading-type1">DESTINATION</td>
                                    <td className="heading-type1">Alto(mini)</td>
                                    <td className="heading-type1">Swift Desire(sedan)</td>
                                    <td className="heading-type1">Innova 6 Seater</td>
                                </tr>
                                    <tr>
                                        <td className="heading-type">LAIDA TO CHANDIGARH</td>
                                        <td> <b>Inr.7,000/</b></td>
                                        <td><b>Inr.9,000/</b></td>
                                        <td><b>Inr.10,000/</b></td>
                                    </tr>
                                    <tr>
                                        <td className="heading-type">LAIDA TO SHIMLA</td>
                                        <td><b>Inr.6,000/</b></td>
                                        <td><b>Inr.8,000/</b></td>
                                        <td><b>Inr.9,000/</b></td>
                                    </tr>
                                    <tr>
                                        <td className="heading-type">LAIDA TO MANALI</td>
                                        <td><b>Inr.4,000/</b></td>
                                        <td><b>Inr.6,000/</b></td>
                                        <td><b>Inr.8,000/</b></td>
                                    </tr>
                                    <tr>
                                        <td className="heading-type">LAIDA TO DHARAMSHALA</td>
                                        <td><b>Inr.5,000/</b></td>
                                        <td><b>Inr.7,000/</b></td>
                                        <td><b>Inr.9,000/</b></td>
                                    </tr>
                                    <tr>
                                        <td className="heading-type">LAIDA TO DALHOUSIE</td>
                                        <td><b>Inr.6,000/</b></td>
                                        <td><b>Inr.8,000/</b></td>
                                        <td><b>Inr.10,000/</b></td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </div>
                </div>                
            </section>
            {scrolltotop()}
        </PrimeReactProvider>  
    );
    
}
export default CarRental;