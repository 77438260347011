function ListTitle_section( props ) {
    return (
        <div className="single-package-title-section sp-data-box">
            <div className="sp-title heading-under-line">
                {props.LData.detail[0].packg_ls_name} 
            </div>
            <div className="sp-info">
                <div className="sp-time">
                    {props.LData.information.dayNightNF} 
                </div>
                <div className="sp-guest-count">
                    {props.LData.information.personsNF} 
                </div>
                <div className="sp-location">
                    {props.LData.information.locationNF} 
                </div>
            </div>
        </div>
    );
}
export default ListTitle_section;