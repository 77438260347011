import React,{useState, useRef} from 'react';

import {gbData} from "../../globalVarbs";

import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { InputText } from 'primereact/inputtext';
import { InputTextarea  } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

function ListInqueryForm(props){

    const [loading, setLoading] = useState(false);

    // toast and formik functions
        const toast = useRef(null);

        const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };

        const isFormFieldInvalid = ( name ) => !!( formik.touched[ name ] && formik.errors[ name ] );

        const getFormErrorMessage = ( name ) => {
            return isFormFieldInvalid( name ) ? <small className="p-error">{ formik.errors[ name ] }</small> : <small className="p-error">&nbsp;</small>;
        };
    // end

    // formik validation and form submit
        const formik = useFormik({
            initialValues: {
                rqstName: '',
                rqstNmbr: '',
                rqstEmail:'',
                rqstMsg: '',
                rqstPersons:'',
                rqstTravelDate:''
            },
            validate: ( data ) => {
                let errors = {};

                if ( !data.rqstName ) {
                    errors.rqstName = 'Name is required.';
                }

                if ( !data.rqstNmbr ) {
                    errors.rqstNmbr = 'Phone Number is required.';
                }

                if ( !data.rqstEmail ) {
                    errors.rqstEmail = 'Email is required.';
                }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.rqstEmail)) {
                    errors.rqstEmail = 'Invalid email address';           
                }

                if ( !data.rqstMsg ) {
                    errors.rqstMsg = 'Message is required.';
                }

                if ( !data.rqstPersons ) {
                    errors.rqstPersons = 'Number of Persons is required.';
                }

                if ( !data.rqstTravelDate ) {
                    errors.rqstTravelDate = 'Travel Date is required.';
                }

                return errors;
            },
            onSubmit: ( data ) => {
                setLoading( true );

                // if( !data.rqstMsg ){
                //     data.rqstMsg = "No Message Sent";
                // }

                fetch( gbData.API_URL + '/send-mail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "name": data.rqstName,
                        "number": data.rqstNmbr,
                        "message": data.rqstMsg,
                        "sendType":"list_inqueiry",
                        "email":data.rqstEmail,
                        "persons":data.rqstPersons,
                        "travel_date":data.rqstTravelDate,
                        "packageName":props.LData.detail[0].packg_ls_name
                    }),
                })
                .then( response => {
                    if ( !response.ok ) {
                        showT('error', 'Failed', 'Network response was not ok' );
                        throw new Error(  );
                        setLoading(false);
                    }
                    return response.json();
                })
                .then( ApiResponse => {
                    // Process the newly created user data
                    // console.log( 'ApiResponse:', ApiResponse );
                    if ( ApiResponse.success == 0 ) {
                        showT( 'error', 'Failed', ApiResponse.message );
                        setLoading( false );
                        return;
                    }

                    setTimeout( function(){
                        showT( 'success', 'Success', ApiResponse.message );
                    }, 100);
                    
                    formik.resetForm();
                    // setVisible(false);
                    setLoading(false);
                    // fetchData();
                })
                .catch(error => {
                    showT( 'error', 'Failed', error );
                    console.error('Error:', error);
                });  
                            
            }
        });
    // end

    return(
        <PrimeReactProvider>
            <Toast ref={toast} position="top-center" />
            <div className="sp-form-section sp-data-box mt-40">
                <div className="booking-title">
                    <div className="sp-title heading-under-line">
                        INQUERY FORM
                    </div>
                </div>
                <div className="sp-inqury-form">
                    <form onSubmit={formik.handleSubmit} >
                        <div className="formfield">
                            <div className="filedtitle"><label className="form-label">Enter Name</label></div>
                            <input type="text" 
                                name="rqstName"
                                value={formik.values.rqstName}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstName', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstName') })}
                            />
                            {getFormErrorMessage('rqstName')}
                        </div>
                        <div className="formfield">
                            <div className="filedtitle"><label className="form-label">Enter Email</label></div>
                            <input type="email" 
                                placeholder="Enter Email"
                                keyfilter="email"
                                name="rqstEmail"
                                value={formik.values.rqstEmail}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstEmail', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstEmail') })}
                            />
                            {getFormErrorMessage('rqstEmail')}
                        </div>
                        <div className="formfield">
                            <div className="filedtitle"><label className="form-label">Enter Phone</label></div>
                            <input type="text" 
                                placeholder="Enter Phone"
                                keyfilter="num"
                                name="rqstNmbr"
                                value={formik.values.rqstNmbr}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstNmbr', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstNmbr') })}
                            />
                            {getFormErrorMessage('rqstNmbr')}
                        </div>
                        <div className="formfield">
                            <div className="filedtitle"><label className="form-label">Chose Date of
                                    Travel</label></div>
                            <input type="date" 
                                name="rqstTravelDate"
                                value={formik.values.rqstTravelDate}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstTravelDate', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstTravelDate') })}
                            />
                            {getFormErrorMessage('rqstTravelDate')}
                        </div>
                        <div className="formfield">
                            <div className="filedtitle"><label className="form-label">How Many Parson</label>
                            </div>
                            <input type="number" 
                                name="rqstPersons"
                                value={formik.values.rqstPersons}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstPersons', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstPersons') })}
                            />
                            {getFormErrorMessage('rqstPersons')}
                        </div>
                        <div className="formfield">
                            <div className="filedtitle"><label className="form-label">Message</label></div>
                            <textarea id="message" cols="30" rows="10"
                                placeholder="Message"
                                name="rqstMsg"
                                value={formik.values.rqstMsg}
                                onChange={(e) => {
                                    formik.setFieldValue('rqstMsg', e.target.value);
                                }}
                                className={classNames('w-full', { 'p-invalid': isFormFieldInvalid('rqstMsg') })}></textarea>
                                {getFormErrorMessage('rqstMsg')}
                        </div>
                        <div className="form-button">
                            <input className="form-submit-button" type="submit" value="submit"/>
                        </div>
                    </form>
                </div>
            </div>
        </PrimeReactProvider>
    );
}
export default ListInqueryForm;