import RequestCall from '../RequestCall';
import subway_call from '../images/icons/subway_call.png';
function ListPriceBox(props){
    return(
        <div className="sp-price-section sp-data-box">
            <div className="booking-title">
                <div className="sp-title heading-under-line">
                    Book The Tour
                </div>
            </div>
            <div className="sp-regular-price">Starting From <span>INR {props.LData.information.pricetNF}</span></div>
            <div className="sp-sale-price">INR {props.LData.information.discountedPricetNF}</div>
            <div className="sp-requst-call-buttons">
                <div className="listing-packege-button-section">
                    <div className="packege-call-button">
                        <a href={`tel:${props.LData.information.pckgContact}`} className="packege-primary-button">
                            <span><img src={subway_call} alt='Call icon'/></span>Call Now
                        </a>
                    </div>
                    <div className="packege-request-call-button">
                        <RequestCall packageName={props.LData.detail[0].packg_ls_name} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ListPriceBox;